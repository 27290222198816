<template>

    <ErrorParsedForm v-if="showError" :message="errorMessage"></ErrorParsedForm>

    <!-- InterpellationPageHead -->
    <div class="interpellation-page-head" v-if="!loading">

        <InterpellationPageHead :header=interpollationData.header />

    </div>
    <!-- /InterpellationPageHead -->
    <!-- InterpellationPageAnswers -->
    <div class="interpellation-answers interpellation-striped" v-if="!loading">
        <InterpellationAnswearItem v-for="asnwear in interpollationData.answers"
                                   :key="asnwear.id"
                                   :asnwear=asnwear
                                   :currentInterpellationID=interpellatonID />
    </div>

    <!-- /InterpellationPageAnswers -->
    <!-- InterpellationAddNewItem-->
    <div class="interpellation-add-new-item" v-if="!interpollationData.header.isPublic">
        <InterpellationAddAnswerItem v-if="!loading" @update-interpellation="updateInterpellation" />
    </div>
    <div class="interpellation-add-new-item" v-if="interpollationData.header.isPublic">
        <h4>Dodawanie odpowiedzi zostało zablokowane przez administratora</h4>
    </div>



    <div class="loader" v-if="loading">
        <div class="row justify-content-center h-100">
            <div class="spinner-border" style="width: 6rem; height: 6rem;" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            <span class="text-muted">Wczytywanie...</span>
        </div>
    </div>
    <!-- /InterpellationAddNewItem-->
</template>
<script>
    import InterpellationPageHead from '@/components/interpellation/InterpellationPageHead.vue'
    import InterpellationAnswearItem from '@/components/interpellation/InterpellationAnswearItem.vue'
    import InterpellationAddAnswerItem from '@/components/interpellation/InterpellationAddAnswerItem.vue'
    import ErrorParsedForm from '@/components/global/ErrorParsedForm.vue';
    export default {
        name: 'InterpellationView',
        computed: {
            interpellatonID() {
                return parseInt(this.$route.params.id)
            }
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                interpollationData: {},
                defaultInterpollationData: {
                    header: {
                        data: '',
                        type: {
                            name: '',
                            id: 0,
                        },
                        title: '',
                        isPublic: false
                    },
                    answers: []
                },
                loading: true,
                showError: false,
                errorMessage: '',
            };
        },
        components: {
            InterpellationPageHead,
            InterpellationAnswearItem,
            InterpellationAddAnswerItem,
            ErrorParsedForm
        },
        created() {
            // fetch the data when the view is created and the data is
            // already being observed
            this.interpollationData = this.defaultInterpollationData;
            this.loading = true;
            this.fetchInterpellation();
        },
        watch: {
            // call again the method if the route changes
            '$route': 'fetchInterpellation'
        },
        methods: {
            async fetchInterpellation() {
                this.loading = true;
                this.showError = false;
                this.errorMessage = ''
                const request = {
                    interpellcationID: this.interpellatonID,
                }
                if (this.interpellatonID > 0) {


                    this.$store.dispatch('interpellation/getByID', request)
                        .then((response) => {
                            this.interpollationData = response

                            let isEmpty = Object.keys(this.interpollationData.header).length === 0;

                            if (isEmpty)
                                this.interpollationData = this.defaultInterpollationData

                            this.loading = false;
                        })
                        .catch((error) => {
                            this.loading = false;
                            this.showError = true;
                            if (error.data.message)
                                this.errorMessage = error.data.message;
                        })
                        .finally(() => {
                            this.loading = false;
                        })
                    //this.loading = false;
                }
            },
            updateInterpellation() {
                this.fetchInterpellation()
            }
        }
    }
</script>