import "bootstrap";
import 'bootstrap/dist/css/bootstrap.css'
import { createApp } from 'vue'
import moment from 'moment'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFontAwesome } from '@fortawesome/free-brands-svg-icons'
import { faUserSecret, faUser, faKey, faDownload, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import App from './App.vue'
import router from './router'
import store from "./store";

import setupInterceptors from './services/setupInterceptors';
setupInterceptors(store, router);

moment.locale('pl');
library.add(faFontAwesome)
library.add(faUser)
library.add(faKey)
library.add(faDownload)
library.add(faUserSecret)
library.add(faTriangleExclamation)

let app = createApp(App)
app.config.globalProperties.$moment = moment;
app.use(router);
app.use(store);
app.use(moment);
app.component('font-awesome-icon', FontAwesomeIcon)
app.mount('#app')
